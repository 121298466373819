import {
  FunnelCreateInterface,
  FunnelDuplicateInterface,
  FunnelEditableInterface,
  FunnelEditableResponseInterface,
  FunnelId,
  FunnelInterface,
} from 'modules/funnels/types/funnel-interface'
import httpClient from 'shared/utils/http-client'

export const FUNNELS_API = '/api/dashboard/customer/funnels'

export const activateFunnel = (id: FunnelId, isActive: boolean) =>
  httpClient.post(`${FUNNELS_API}/${isActive ? 'deactivate' : 'activate'}/${id}`)

export const createFunnel = (data: FunnelCreateInterface) => httpClient.post(FUNNELS_API, data)

export const duplicateFunnel = (id: FunnelId, data: FunnelDuplicateInterface) =>
  httpClient.post<FunnelInterface>(`${FUNNELS_API}/duplicate-background/${id}`, data)

export const updateFunnel = (id: FunnelId, data: FunnelEditableInterface) =>
  httpClient.put<FunnelEditableResponseInterface>(`${FUNNELS_API}/${id}`, data)

export const removeFunnel = (id: FunnelId) => httpClient.delete(`${FUNNELS_API}/${id}`)
