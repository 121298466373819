import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useCallback, useMemo, useState } from 'react'
import { QueryKeysEnum } from 'shared/route-query/enum/query-keys-enum'
import { useQuery } from 'shared/route-query/hooks/use-query'
import { getFilterQuery } from 'shared/route-query/utils/get-filter-query'
import { getLimitQuery } from 'shared/route-query/utils/get-limit-query'
import { getPaginationQuery } from 'shared/route-query/utils/get-pagination-query'
import { setFilterFromQuery } from 'shared/route-query/utils/set-filter-from-query'
import { setLimitFromQuery } from 'shared/route-query/utils/set-limit-from-query'
import { setPaginationFromQuery } from 'shared/route-query/utils/set-pagination-from-query'
import { LimitType, OrderEnum, PaginationInterface } from 'shared/types/pagination-interface'

export const useFilterPagination = <T>(defaultFilter: T, isStaticUrl?: boolean) => {
  const { query } = useRouter()

  const [filter, setFilter] = useState<T>(defaultFilter)

  const [limit, setLimit] = useState<LimitType>(setLimitFromQuery(query))

  const [pagination, setPagination] = useState<PaginationInterface>(setPaginationFromQuery(query))

  const setFirstPage = useCallback(() => {
    setPagination({ startFromId: undefined, order: OrderEnum.next })
  }, [])

  const clearPagination = useCallback(() => {
    setFirstPage()
    setLimit(10)
  }, [setFirstPage])

  const memoizedQueryData = useMemo(() => {
    const newQueryData: ParsedUrlQuery = {
      ...getPaginationQuery(pagination),
      ...getLimitQuery(limit),
    }

    try {
      const filterQuery = getFilterQuery({ filter, defaultFilter })
      if (Object.keys(filterQuery).length !== 0) {
        newQueryData['filter'] = JSON.stringify(filterQuery)
      }
    } catch {}

    return newQueryData
  }, [defaultFilter, filter, limit, pagination])

  useQuery({
    data: memoizedQueryData,
    skip: isStaticUrl,
    onChange: query => {
      const limit = setLimitFromQuery(query)
      setLimit(limit)

      const pagination = setPaginationFromQuery(query)
      setPagination(pagination)

      const filter = setFilterFromQuery(query)
      if (filter) {
        try {
          const newFilter = { ...defaultFilter, ...JSON.parse(filter) }
          setFilter(newFilter)
        } catch {}
      } else {
        setFilter(defaultFilter)
      }
    },
    keys: Object.values(QueryKeysEnum),
  })

  return {
    limit,
    setLimit,
    pagination,
    setPagination,
    filter,
    setFilter,
    clearPagination,
    setFirstPage,
  }
}
