import React from 'react'

function TimeCircleIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="#CBD5E1"
      {...props}
    >
      <circle cx="10.1884" cy="10.1875" r="10.04" />
      <path
        d="M10.1895 6.79727V10.1875L12.4496 11.3176M15.8398 10.1875C15.8398 13.3081 13.3101 15.8379 10.1895 15.8379C7.06883 15.8379 4.53906 13.3081 4.53906 10.1875C4.53906 7.06688 7.06883 4.53711 10.1895 4.53711C13.3101 4.53711 15.8398 7.06688 15.8398 10.1875Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TimeCircleIcon
